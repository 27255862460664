import React, { useEffect, useState, Fragment } from "react";
import { Tab } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useParams } from "react-router-dom";
import Layout from "./../../layouts/Main";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showBid, cancelBid } from "./../../store/actions/BidActions";
import { getProductById } from "./../../store/actions/ProductActions";
import { getMoqById } from "../../store/actions/MoqActions";
import { toast } from "react-toastify";
import moment from "moment/moment";
import instance from "../../utils/instance";
import ContentLoader from "react-content-loader";
import withRouter from "../../utils/withRouter";
import { registerGtagEvent, isAuthenticated } from "../../utils/helpers";
import RecommendedProducts from "../../components/RecommendedProducts";

const authenticated = isAuthenticated();

const BlockLoader = ({
  height = 300,
  width = "100%",
  radius = 8,
  uniqueKey = "unique",
}) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    speed={2}
    width={width}
    height={height}
    backgroundColor="#ECEBEB"
    foregroundColor="#b0b0b0"
  >
    <rect
      x="0"
      y="0"
      rx={`${radius}`}
      ry={`${radius}`}
      width="100%"
      height={`${height}`}
    />
  </ContentLoader>
);

const DeleteBidModal = ({ isOpen, closeModal, deleteBid }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Delete Order
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to delete the order ?
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-[#D30B0B] px-4 py-2 text-sm font-medium text-white hover:bg-[#F1592A] focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => deleteBid()}
                  >
                    Delete
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Show = (props) => {
  // Props
  const {
    bids: { bid },
    products: { product },
    moqs: { moq },
  } = props;

  // States
  const [img, setImg] = useState(product !== null ? product?.images[0] : "");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showPaymentInstructions, setShowPaymentInstructions] = useState(
    authenticated ? false : true
  );
  const [attributes, setAttributes] = useState([]);
  const [pesapalUrl, setPesapalUrl] = useState(null);

  // Hooks
  const params = useParams();

  // Effects
  useEffect(() => {
    async function fetchBid(id) {
      let response = await props.showBid(id);

      if (response && response?.length > 0 && response[0]?.product[0]?.id) {
        await props.getProductById(response[0]?.product[0]?.id);

        await props.getMoqById(response[0]?.moq_id);
        payViaPesapal(response[0]);
      }
    }

    fetchBid(params?.id);
  }, []);

  useEffect(() => {
    if (bid) {
      // Redirecting user to homepage and moq is not full (status 1 means bid has been paid for and also means that MOQ is full in this case,)
      if (!authenticated && bid.moq_full === 0) {
        window.location.href = "/";

        return;
      }

      if (!authenticated && bid.shipping_status === 1) {
        // Redirecting user to home page if not authenticated and the shipping fee has been paid for.
        window.location.href = "/";

        return;

        // Redirecting user to my orders page if authenticated and the shipping fee has been paid for.
      } else if (authenticated && bid.shipping_status === 1) {
        // window.location.href = "/orders";
        // return;
        // showing payment instruction page if bid has been paid for but the shipping status has not been paid for
      } else if (
        (bid?.moq_full === 1 && bid.status === 0) ||
        (bid?.moq_full === 1 &&
          bid.status === 1 &&
          bid.total_shipping > 0 &&
          bid.shipping_status === 0)
      ) {
        setShowPaymentInstructions(true);
      }
      if (
        !authenticated &&
        bid?.moq_full === 1 &&
        bid.status === 1 &&
        bid.total_shipping === null &&
        bid.shipping_status === 0
      ) {
        setShowPaymentInstructions(false);
      }
      // if (
      //   bid &&
      //   bid?.moq_full === 1 &&
      //   bid.status === 1 &&
      //   bid.shipping_status === 0 &&
      //   bid.total_shipping === null &&
      //   bid?.moq_order &&
      //   bid?.moq_order[0]?.status !==
      //     "Submitted - Pending supplier acknowledgement"
      // ) {
      //   // Redirecting users to home page if the bid has been paid for but no shipping details are present
      //   window.location.href = "/";

      //   return;
      // }

      if (bid?.bid_attributes && bid?.bid_attributes?.length > 0)
        setAttributes(bid?.bid_attributes);
    }
  }, [bid]);

  useEffect(() => {
    if (product !== null && product?.images[0]) {
      setImg(product?.images[0]);
    }
  }, [product]);

  // Functions
  const closeCancelBidModal = () => setIsOpen(false);

  const deleteBid = async () => {
    try {
      setLoading(true);

      await props.cancelBid(params?.id);

      setLoading(false);

      setIsOpen(false);

      toast.success("Order removed successfully !", { autoClose: 10000 });

      return (window.location.href = "/orders");
    } catch (error) {
      setLoading(false);

      setIsOpen(false);

      console.log(error);
    }
  };

  const checkout = async () => {
    try {
      setProcessing(true);

      let account_number = `${bid.bid_number}`;

      if (
        bid &&
        bid.status === 1 &&
        bid.shipping_status === 0 &&
        bid?.hasOwnProperty("moq_order") &&
        bid.moq_order.length > 0 &&
        bid.moq_order[0].status ===
          "Goods arrived at Litudian - Ready to collect"
      ) {
        account_number = `SF-${bid.bid_number}`;
      }

      const response = await instance.post(`auto_mpesa_checkout`, {
        account_number,
      });

      if (response.data) {
        setProcessing(false);

        return toast.success(
          `You will receive a prompt on mobile number ${
            bid && bid?.user ? bid?.user[0]?.msisdn : "N/A"
          } to enter your MPESA service PIN to authorize your payment request of KES ${
            bid.amount
          }`
        );
        registerGtagEvent("purchase");
      }
    } catch (error) {
      setProcessing(false);

      console.log(error);

      return toast.error("Please try again later !");
    }
  };

  const payViaPesapal = async (val = null) => {
    try {
      let account_number = `${val?.bid_number}`;
      if (
        val &&
        val.status === 1 &&
        val.shipping_status === 0 &&
        val?.hasOwnProperty("moq_order") &&
        val.moq_order.length > 0 &&
        val.moq_order[0].status ===
          "Goods arrived at Litudian - Ready to collect"
      ) {
        account_number = `SF-${val.bid_number}`;
      }

      setLoading(true);
      let response = await instance.get(`/pesapal-payments/${account_number}`);
      if (response?.data?.card_payment_url) {
        setPesapalUrl(response?.data?.card_payment_url);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.error) {
        return toast.error(`${error?.response?.data?.error}`);
      }
    }
  };

  let account_number = bid ? bid.bid_number : "N/A",
    price = bid ? bid.amount : 0,
    title = "Pay With M-Pesa",
    title2 = "Pay Via Card";

  if (
    bid &&
    bid?.moq_full === 1 &&
    bid.status === 1 &&
    bid.shipping_status === 0 &&
    bid?.hasOwnProperty("moq_order") &&
    bid.moq_order.length > 0 &&
    bid.moq_order[0].status === "Goods arrived at Litudian - Ready to collect"
  ) {
    account_number = `SF-${bid.bid_number}`;

    price = bid.total_shipping;

    title = "Pay Shipping Fee With M-Pesa";

    title2 = "Pay Shipping Fee Via Card";
  }

  return (
    <Layout>
      {(!bid || !product) && <BlockLoader height={"80vh"} radius={4} />}

      {bid && product && (
        <>
          <div className="flex flex-row gap-x-3 my-5">
            <h1 className="text-tangerine font-bold uppercase">
              {product?.name}
            </h1>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-12 p-1 gap-x-10 mb-8">
            <div className="md:col-span-4 flex flex-col space-y-2 p-1">
              <div className="w-full">
                <img
                  className="h-100 object-cover w-full"
                  src={img || "https://via.placeholder.com/300"}
                  alt={"product"}
                  onError={(e) => {
                    e.target.src = "https://via.placeholder.com/300";
                  }}
                />
              </div>

              <div className="grid grid-cols-4 gap-2 mt-3">
                {product !== null &&
                  product.images !== undefined &&
                  product.images.length > 1 &&
                  product.images.slice(0, 4).map((image, i) => (
                    <div
                      key={i}
                      className="bg-white w-full rounded-md shadow-sm cursor-pointer"
                      onClick={() =>
                        setImg(image || "https://via.placeholder.com/300")
                      }
                    >
                      <img
                        className="h-[100px] object-cover w-full"
                        src={image || "https://via.placeholder.com/300"}
                        alt={"product"}
                        onError={(e) => {
                          e.target.src = "https://via.placeholder.com/300";
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div className="md:col-span-8 bg-white px-4 md:px-8 py-4 md:py-8 rounded-sm">
              {!showPaymentInstructions && (
                <div className="flex flex-col space-y-5 h-full">
                  <h1 className="text-[#5B5B5B] capitalize text-2xl font-semibold">
                    {product?.name}
                  </h1>

                  <div className="flex flex-col space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-4">
                      <div className="md:col-span-1 flex flex-row justify-between items-center">
                        <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                          Categories
                        </p>

                        <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                          :
                        </p>
                      </div>

                      <div className="md:col-span-3 flex flex-row justify-between items-center md:px-6">
                        <p className="text-[#5B5B5B] text-md md:text-lg">
                          {product !== null && product?.category_name}
                        </p>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-4">
                      <div className="md:col-span-1 flex flex-row justify-between items-center">
                        <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                          Order Quantity
                        </p>

                        <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                          :
                        </p>
                      </div>

                      <div className="md:col-span-3 flex flex-row justify-between items-center md:px-6">
                        <p className="text-[#5B5B5B] text-md md:text-lg">
                          {bid?.quantity} items
                        </p>
                      </div>
                    </div>

                    {attributes?.length > 0 && (
                      <div className="grid grid-cols-1 md:grid-cols-4">
                        <div className="md:col-span-1 flex flex-row justify-between items-start">
                          <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                            Order Specification
                          </p>

                          <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                            :
                          </p>
                        </div>
                        <div className="md:col-span-3 flex flex-row justify-between items-start md:px-6">
                          <ul className="text-[#5B5B5B] text-md md:text-lg">
                            {attributes?.map((attr, i) => (
                              <li key={i}>
                                <div className="flex flex-row items-center justify-start space-x-1">
                                  <span className="capitalize">
                                    {attr?.quantity}
                                    {attr?.colors?.label !== "None" &&
                                      attr?.colors?.value !== "None" &&
                                      `, ${attr?.colors?.label}`}
                                  </span>
                                  {attr?.colors?.label !== "None" &&
                                    attr?.colors?.value !== "None" && (
                                      <div
                                        className={`w-5 h-5`}
                                        style={{
                                          backgroundColor: `${attr?.colors?.value}`,
                                        }}
                                      ></div>
                                    )}
                                  {attr?.sizes?.value !== "None" && (
                                    <React.Fragment>
                                      {attr?.colors?.label !== "None" &&
                                        attr?.colors?.value !== "None" &&
                                        ","}
                                      <span className="capitalize">
                                        size {attr?.sizes?.label}
                                      </span>
                                    </React.Fragment>
                                  )}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}

                    <div className="grid grid-cols-1 md:grid-cols-4">
                      <div className="md:col-span-1 flex flex-row justify-between items-center">
                        <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                          Shipping Method
                        </p>
                        <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                          :
                        </p>
                      </div>

                      <div className="md:col-span-3 flex flex-row justify-between items-center md:px-6">
                        <p className="text-[#5B5B5B] text-md md:text-lg">
                          {bid && bid?.shipping}
                        </p>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-4">
                      <div className="md:col-span-1 flex flex-row justify-between items-center">
                        <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                          Payment Status
                        </p>

                        <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                          :
                        </p>
                      </div>

                      <div className="md:col-span-3 flex flex-row justify-between items-center md:px-6">
                        <div className="flex flex-row justify-end items-center space-x-2">
                          <span
                            className={`${
                              bid?.status === 0 ? "bg-red-500" : "bg-green-500"
                            } text-white text-sm rounded-lg capitalize shadow px-4 py-1`}
                          >
                            {bid?.status === 0 ? "unpaid" : "paid"}
                          </span>
                        </div>
                      </div>
                    </div>

                    {bid?.status === 1 && (
                      <div className="grid grid-cols-1 md:grid-cols-4">
                        <div className="md:col-span-1 flex flex-row justify-between items-center">
                          <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                            Payment Date
                          </p>

                          <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                            :
                          </p>
                        </div>

                        <div className="md:col-span-3 flex flex-row justify-between items-center md:px-6">
                          <div className="flex flex-row justify-end items-center space-x-2">
                            <p className="text-[#5B5B5B] text-md md:text-lg">
                              {bid &&
                                bid?.date_updated &&
                                moment(bid?.date_updated).format("Do MMM YYYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="grid grid-cols-1 md:grid-cols-4">
                      <div className="md:col-span-1 flex flex-row justify-between items-center">
                        <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                          Delivery Status
                        </p>
                        <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                          :
                        </p>
                      </div>

                      <div className="md:col-span-3 flex flex-row justify-between items-center md:px-6">
                        <p className="text-[#5B5B5B] text-md md:text-lg">
                          {bid && bid?.delivery_status === 0
                            ? "Undelivered"
                            : "Delivered"}
                        </p>
                      </div>
                    </div>

                    {bid &&
                      bid?.delivery_status === 1 &&
                      bid?.date_delivered && (
                        <div className="grid grid-cols-1 md:grid-cols-4">
                          <div className="md:col-span-1 flex flex-row justify-between items-center">
                            <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                              Delivery Date
                            </p>
                            <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                              :
                            </p>
                          </div>

                          <div className="md:col-span-3 flex flex-row justify-between items-center md:px-6">
                            <p className="text-[#5B5B5B] text-md md:text-lg">
                              {bid &&
                                bid?.date_delivered &&
                                moment(bid?.date_delivered).format(
                                  "Do MMM YYYY"
                                )}
                            </p>
                          </div>
                        </div>
                      )}

                    {bid?.hasOwnProperty("moq_order") &&
                      bid.moq_order.length > 0 && (
                        <div className="grid grid-cols-1 md:grid-cols-4">
                          <div className="md:col-span-1 flex flex-row justify-between items-center">
                            <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                              ETA
                            </p>

                            <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                              :
                            </p>
                          </div>

                          <div className="md:col-span-3 flex flex-row justify-between items-center md:px-6">
                            <div className="flex flex-row justify-end items-center space-x-2">
                              <p className="text-[#5B5B5B] text-md md:text-lg">
                                {bid.moq_order[0].eta
                                  ? moment(bid.moq_order[0].eta).format(
                                      "YYYY-MM-DD"
                                    )
                                  : "To be confirmed"}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                    {!(
                      bid?.delivery_status === 1 && bid?.date_delivered !== null
                    ) && (
                      <div className="grid grid-cols-1 md:grid-cols-4">
                        <div className="md:col-span-1 flex flex-row justify-between items-center">
                          <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                            Collection Status
                          </p>

                          <p className="text-[#5B5B5B] font-bold text-md md:text-lg font-futuraBold">
                            :
                          </p>
                        </div>

                        <div className="md:col-span-3 flex flex-row justify-between items-center md:px-6">
                          <span
                            className={`${
                              bid?.is_collected === 0
                                ? "bg-red-500"
                                : "bg-green-500"
                            } text-white text-sm px-3 py-1 rounded-lg shadow capitalize`}
                          >
                            {bid?.is_collected === 0
                              ? "not collected"
                              : "collected"}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col justify-between h-fit space-y-3">
                    <div className="flex flex-row justify-start items-center space-x-4">
                      {moq !== null &&
                        moq.moq_full === 1 &&
                        bid?.status === 0 && (
                          <button
                            className={`bg-green-600 text-white text-base px-5 py-2 rounded-lg capitalize shadow`}
                            onClick={() => setShowPaymentInstructions(true)}
                          >
                            Pay with M-Pesa
                          </button>
                        )}
                    </div>

                    <div className="flex flex-row justify-start items-center space-x-4">
                      {((bid?.status === 0 && bid?.moq_full === 0) ||
                        (bid?.moq_full === 1 && bid.status === 0)) &&
                        authenticated && (
                          <button
                            className={`bg-[#F1592A] text-white text-base px-5 py-2 capitalize shadow rounded-lg`}
                            onClick={() => {
                              return (window.location.href = `/items/${params?.id}/edit`);
                            }}
                          >
                            Edit Bid
                          </button>
                        )}

                      {bid?.status === 0 &&
                        bid?.moq_full === 0 &&
                        authenticated && (
                          <button
                            className={`bg-[#D30B0B] text-white text-base px-5 py-2 rounded-lg capitalize shadow`}
                            onClick={() => setIsOpen(true)}
                          >
                            Cancel Bid
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              )}

              {showPaymentInstructions && (
                <div className="flex flex-col space-y-5 h-full">
                  <a
                    className="text-blue-500"
                    href={`https://litudian.s3.amazonaws.com/invoice_${bid.bid_number}.pdf`}
                  >
                    Download Invoice
                  </a>

                  <Tab.Group>
                    <Tab.List className="flex flex-row items-center justify-start w-full space-x-4">
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            `rounded-lg py-2.5 capitalize focus:outline-none ${
                              selected
                                ? "text-white bg-litudian-orange"
                                : "text-[#5B5B5B] bg-gray-200"
                            } text-left px-10`
                          )
                        }
                      >
                        <h1 className="capitalize text-1xl font-semibold">
                          {title}
                        </h1>
                      </Tab>
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            `rounded-lg py-2.5 capitalize focus:outline-none ${
                              selected
                                ? "text-white bg-litudian-orange"
                                : "text-[#5B5B5B] bg-gray-200"
                            } text-left px-10`
                          )
                        }
                      >
                        <h1 className="capitalize text-1xl font-semibold">
                          {title2}
                        </h1>
                      </Tab>
                    </Tab.List>
                    <Tab.Panels className="w-full">
                      <Tab.Panel className={"space-y-4"}>
                        <p>
                          To pay via MPESA, click the "Lipa Na Mpesa" button
                          below. You will receive a prompt on mobile number{" "}
                          {bid?.user[0]?.msisdn || "N/A"} to enter your MPESA
                          service PIN to authorize your payment request of KES{" "}
                          {price} to Litudian
                        </p>

                        <div className="flex flex-row items-center justify-start space-x-5">
                          <button
                            className="bg-green-600 px-3 py-1 rounded w-fit text-white"
                            disabled={processing}
                            onClick={() => checkout()}
                          >
                            {processing ? "Waiting ..." : "Lipa Na Mpesa"}
                          </button>
                        </div>

                        <p>
                          If you do not receive this instructions within 30
                          seconds then follow the following instructions:
                        </p>

                        <p>
                          To pay your bill (KES {price}) via MPESA, Follow the
                          steps below. Once you receive a successfull reply from
                          Mpesa, Click the complete button below
                        </p>

                        <div className="md:px-5">
                          <ol className="list-decimal">
                            <li>Go to your Mpesa on you phone</li>

                            <li>Select the 'Paybill' option</li>

                            <li>Enter Business No. 799643</li>

                            {bid?.hasOwnProperty("moq_order") &&
                            bid.moq_order.length > 0 &&
                            bid.moq_order[0].status ===
                              "Goods arrived at Litudian - Ready to collect" ? (
                              <li>Enter Account No. {account_number}</li>
                            ) : (
                              <li>Enter Account No. {account_number}</li>
                            )}

                            <li>Enter KES {price} as the amount</li>

                            <li>Enter PIN</li>

                            <li>
                              You will receive a confirmation SMS from MPESA
                            </li>
                          </ol>
                        </div>
                      </Tab.Panel>
                      <Tab.Panel className={"space-y-4"}>
                        {pesapalUrl !== null ? (
                          <div>
                            <h1 className="text-[#5B5B5B] normal-case text-base font-semibold text-center">
                              A 3% transaction processing fee will be added to
                              your total payment for Pesapal transactions
                            </h1>
                            <iframe
                              className="w-full h-96 overflow-hidden"
                              src={pesapalUrl}
                              title="Pesapal"
                            ></iframe>
                          </div>
                        ) : (
                          <div className="h-96 w-full flex flex-col justify-center items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="h-36 w-36 mb-2 text-tangerine"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                              />
                            </svg>

                            <h1 className="text-[#5B5B5B]">
                              Pay via Card Details Not Available
                            </h1>
                          </div>
                        )}
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>

                  <h1 className="text-[#5B5B5B] normal-case text-base font-semibold text-center">
                    NB: Please use only one method per transaction to avoid
                    making multiple payments
                  </h1>

                  <div className="flex flex-row justify-end items-center space-x-4">
                    {authenticated && (
                      <button
                        className={`bg-[#F1592A] text-white text-base px-5 py-2 rounded-lg capitalize shadow`}
                        onClick={() => setShowPaymentInstructions(false)}
                      >
                        Back to Order Details
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <RecommendedProducts
            category_id={moq?.product[0]?.category_id || null}
            moq_id={moq?.id}
          />

          <div className="grid-cols-1 md:grid-cols-3 bg-white rounded-md shadow-md my-5 p-4 md:gap-x-24 hidden"></div>
        </>
      )}
      <DeleteBidModal
        isOpen={isOpen}
        closeModal={closeCancelBidModal}
        loading={loading}
        deleteBid={deleteBid}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories,

    moqs: state.moqs,

    bids: state.bids,

    products: state.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { showBid, getProductById, cancelBid, getMoqById },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Show));
