import React, { useEffect, useState } from "react";
import MOQ from "../components/MOQ";
import instance from "../utils/instance";
import ContentLoader from "react-content-loader";

const BlockLoader = ({
  height = 300,
  width = "100%",
  radius = 8,
  uniqueKey = "unique",
}) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    speed={2}
    width={width}
    height={height}
    backgroundColor="#ECEBEB"
    foregroundColor="#b0b0b0"
  >
    <rect
      x="0"
      y="0"
      rx={`${radius}`}
      ry={`${radius}`}
      width="100%"
      height={`${height}`}
    />
  </ContentLoader>
);

function Index(props) {
  // Props
  const { moq_id, category_id = null, title = "Recommended Products" } = props;

  // States
  const [moqs, setMoqs] = useState([]);
  const [loading, setLoading] = useState(false);

  // Effects
  useEffect(() => {
    getMoqs(category_id);
  }, [category_id]);

  const getMoqs = async (id) => {
    try {
      if (!id) return;
      setLoading(true);
      const response = await instance.get(`moq/category/${id}`);
      setLoading(false);
      // let data = [];
      if (response.data.moqs) {
        let data = response.data.moqs || [];
        // Filter out moqs where the id equals the moq_id
        data = data.filter((moq) => moq.id !== moq_id);
        // Shuffle and slice the filtered array to limit to six items
        data = shuffleArray([...data]).slice(0, 6);
        setMoqs(data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Return an empty component if no MOQs are available
  if (!loading && moqs.length === 0) return;

  return (
    <div className="flex flex-col w-full space-y-4 mb-8">
      <h1 className="text-tangerine font-bold uppercase">{title}</h1>
      <div className="grid grid-cols-1 md:grid-cols-6 gap-2">
        {loading &&
          Array(6)
            .fill(null)
            .map((_, i) => (
              <BlockLoader
                key={i}
                uniqueKey={`key${i}`}
                height={300}
                radius={6}
              />
            ))}
        {!loading &&
          moqs.length > 0 &&
          moqs.map((moq, i) => <MOQ key={i} moq={moq} />)}
      </div>
    </div>
  );
}

export default Index;
